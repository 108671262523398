import * as Fhir from "../classes/FhirModules/Fhir";

export class OrganizationService {
    private static _organizations: any[] = [];

    public static get Organizations() {
        if (!this._organizations) this._organizations = [];
        return this._organizations;
    }

    public static Add(organization) {
        if (!organization) return;
        const idx = this.Organizations.findIndex(o => o.id === organization.id);
        if (idx > -1) this.Organizations[idx] = organization;
        else this.Organizations.push(organization);
    }

    public static Get(id: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                if (id.indexOf('/') > -1) id = id.split('/')[1];

                let organization = this.Organizations.find(o => o.id === id);
                if (typeof organization === "undefined") {
                    organization = <any>await Fhir.Rest.Get(`Organization/${id}`);
                }

                resolve(organization);
            } catch (e) {
                reject(e.message || e);
            }
        })
    }
}
