import {ConfigService} from "./ConfigService";
import {UserService} from "./UserService";

export class LogoutService {
    logoutTimerHandler : number;

    public refreshLogoutTimer() {
        if (ConfigService?.cfg?.features?.autoLogout) {
            const al = ConfigService.cfg?.features?.autoLogout;
            if (al.enabled && al.seconds > 0 && UserService.IsLoggedIn) {
                if (this.logoutTimerHandler) {
                    window.clearTimeout(this.logoutTimerHandler);
                    this.logoutTimerHandler = undefined;
                }

                this.logoutTimerHandler = window.setTimeout(() => {
                    console.warn('Automatically Logged out');
                    window.clearTimeout(this.logoutTimerHandler);
                    this.logoutTimerHandler = undefined;
                    sessionStorage.clear();
                    window.location.href = `${window.location.origin}${window.location.pathname}${window.location.search}`;
                }, al.seconds * 1000);
            }
        }
    }
}
